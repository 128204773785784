import React, { useCallback, useContext, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LangContext from "../context/LangContext";
import { AllDirectusPhiloGalery, Illustration, Pages } from "../models";

interface Props {
  allDirectusPhiloGalery: AllDirectusPhiloGalery;
  site: {
    siteMetadata: {
      pages: Pages;
    };
  };
}

interface Photo {
  src: string;
  width: number;
  height: number;
  caption: string;
}

const extractPhoto: (image: Illustration, caption: string) => Photo = (image, caption) => ({
  src: image.data.full_url,
  width: Math.round(image.width / image.height),
  height: Math.round(image.height / image.width),
  caption,
});

const Galerie: React.FC<PageProps<Props>> = ({ data }) => {
  const photos = data.allDirectusPhiloGalery.nodes.map(n => extractPhoto(n.image, n.name));
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const ctx = useContext(LangContext);
  const title = data.site.siteMetadata.pages.galery[ctx.lang];

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout>
      <SEO title={title} />
      <div className="gallery page-container">
        <h1>{title}</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  source: x.src,
                  caption: x.caption,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  );
};
export default Galerie;

export const query = graphql`
  {
    allDirectusPhiloGalery {
      nodes {
        name
        image {
          data {
            full_url
          }
          height
          width
        }
      }
    }
    site {
      siteMetadata {
        pages {
          galery {
            fr
            de
            it
            en
          }
        }
      }
    }
  }
`;
